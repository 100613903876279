@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Urbanist:wght@100;200;300;400;500;600;700;800;900&display=swap");
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
:root {
  --font: "Urbanist", sans-serif;

  --first-color: #ff2e2e;

  --text-light: #ffffff;
  --text-color: #0f172a;
  --text-color-second: #343434;
  --text-color-light: #879cc2;

  --bg-light: #ffffff;
  --bg-main: #f1f5f9;
}

body {
  width: 100%;
  min-height: 100vh;
  font-family: var(--font);
  background-color: var(--bg-main);
}
body.dark-mode {
  --text-color: #f6f2f2;
  --text-color-light: #6c7e9e;

  --bg-light: #111827;
  --bg-main: #172031;
}
.first-color {
  color: var(--first-color);
}
.bg-first-color {
  background-color: var(--first-color);
}
.text-light {
  color: var(--text-light);
}
.text-color {
  color: var(--text-color);
}
.text-color-second {
  color: var(--text-color-second);
}
.text-color-light {
  color: var(--text-color-light);
}
.bg-first {
  background-color: var(--first-color);
}
.bg-light {
  background-color: var(--bg-light);
}
.bg-main {
  background-color: var(--bg-main);
}
.bg-second {
  background-color: var(--bg-second);
}
.bg-opacity {
  background-color: var(--bg-opacity);
}
.shadow-box {
  box-shadow: 5px 4px 20px 4px #7b808a16;
}
.swiper {
  cursor: grab;
  padding: 0 1rem !important;
}
.swiper-pagination {
  height: 8px;
  display: flex;
  align-items: center;
  position: absolute !important;
  top: 0.25rem !important;
  display: flex !important;
  justify-content: center;
  padding: 0 1.25rem !important;
}
.swiper-pagination-bullet {
  width: 100% !important;
  border-radius: 0.1rem !important;
  height: 4px !important;
  background: #fff !important;
}
.line-clamp-2 {
  overflow: hidden;
  display: -webkit-box;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.popup-opacity,
.popup-content {
  background-color: red;
  position: fixed;
  width: 768px;
  max-width: 100%;
  margin: 0 auto;
  left: 50%;
  transform: translate(-50%, 0);
  z-index: 0;
}
.popup-opacity {
  top: 0;
  height: 100vh;
  background-color: #000;
  opacity: 0;
  transition: none;
}
.popup-opacity.show {
  opacity: 0.5;
  z-index: 30;
}
.popup-content {
  bottom: -100%;
  padding: 1rem 1rem 40px 1rem;
  background-color: var(--bg-light);
  min-height: max-content;
  max-height: 80vh;
  overflow-y: auto;
  border-top-left-radius: 24px;
  border-top-right-radius: 24px;
  transition: all 0.25s;
}
.popup-content.show {
  bottom: 0;
  z-index: 35;
}
